<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="650"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.UPLOAD_FOTO_DOCUMENTATION}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="3">
            {{$_strings.order.DOCUMENT_TYPE}}
            <h3 class="error--text d-inline">
              *
            </h3>
          </v-col>
          <v-col class="pb-0">
            <v-select
              dense
              outlined
              :items="documentItems"
              @change="handleChangeDocumentType"
              v-model="documentType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            {{$_strings.order.DOCUMENT_PHOTOS}}
            <h3 class="error--text d-inline">
              *
            </h3>
          </v-col>
          <v-col class="pb-0">
            <v-row>
              <v-col cols="4" v-for="(form, index) in formUploads" :key="index">
                <v-card
                  class="mx-auto"
                  outlined
                  @mouseenter="handleTooltip"
                  @mouseleave="handleTooltip"
                >
                  <v-card
                    v-if="!form.encodedImages[0]"
                    height="150"
                    class="d-flex justify-center align-center"
                    :disabled="!documentType"
                    flat
                    @click="$refs['file-upload'+index][0].$refs.input.click()"
                  >
                    <v-tooltip v-model="showTooltip" left>
                      <template v-slot:activator="{ }">
                        <v-icon color="grey">
                          mdi-plus
                        </v-icon>
                      </template>
                      <p>Tipe Dokumen Belum Dipilih</p>
                    </v-tooltip>
                    <v-file-input
                      class="d-none"
                      :ref="'file-upload'+index"
                      type="file"
                      accept="image/*"
                      @change="(e) => readFile(e, form)"
                      v-model="form.file"
                    />
                  </v-card>
                  <div
                    v-else
                    @mouseenter="handleHover(index)"
                    @mouseleave="handleHover(index)"
                  >
                    <v-img
                      contain
                      aspect-ratio="1"
                      height="150"
                      :src="form.encodedImages[0]"
                      :style="form.uploadProgress || form.hover ? 'opacity:.5' : ''"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <div
                      v-if="form.hover && !form.uploadProgress"
                      class="text-center wrapper-trash"
                    >
                      <v-icon
                        color="red"
                        large
                        @click="deleteDocument(index)"
                      >
                        mdi-trash-can
                      </v-icon>
                    </div>
                    <div v-if="form.uploadProgress" class="text-center wrapper-progress">
                      <v-progress-circular
                        :rotate="360"
                        :size="100"
                        :width="15"
                        :value="form.uploadProgress"
                        color="primary"
                      >
                        {{ form.uploadProgress }}
                      </v-progress-circular>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col v-if="formUploads.length && formUploads[formUploads.length - 1].encodedImages[0]" class="d-flex align-center">
                <v-icon @click="addDocument" color="blue">
                  mdi-plus-circle
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="saveShipmentDocumentAdditional"
          :disabled="(formUploads.length && !formUploads[0].encodedImages[0]) || isLoading"
        >
          {{$_strings.common.UPLOAD}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      shipment: {}, // FROM DOCUMENT
      valid: false,
      dialog: false,
      isLoading: false,
      documentType: null,
      shipmentId: '',
      formUploads: [
        {
          locationId: '',
          documentType: '',
          documentName: '',
          encodedImages: [],
          // tools
          file: null,
          uploadProgress: 0,
          hover: false,
        },
      ],
      showTooltip: false,
      value: 20,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        source = CancelToken.source();
      }
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.imageSelected = '';
        this.shipmentId = '';
        this.documentType = null;
        this.formUploads = [
          {
            locationId: '',
            documentType: '',
            documentName: '',
            encodedImages: [],
            // tools
            file: null,
            uploadProgress: 0,
            hover: false,
          },
        ];
      }
    },
  },
  computed: {
    documentItems() {
      const { orderStatus = '' } = this.shipment;
      const items = [
        {
          value: 'FOTO_MUAT_BARANG',
          text: 'Foto Muat Barang',
        },
        {
          value: 'FOTO_BONGKAR_BARANG',
          text: 'Foto Bongkar Barang',
        },
        {
          value: 'FOTO_SURAT_JALAN',
          text: 'Foto Surat Jalan',
        },
        {
          value: 'FOTO_SURAT_SERAH_TERIMA',
          text: 'Foto Surat Serah Terima',
        },
        {
          value: 'FOTO_BASP',
          text: 'Foto BASP',
        },
        {
          value: 'FOTO_BUKTI_BASP',
          text: 'Foto Bukti BASP',
        },
      ];
      let showDocuments = [];
      if (orderStatus.toLowerCase() === 'di lokasi penjemputan') {
        showDocuments = ['FOTO_SURAT_JALAN', 'FOTO_MUAT_BARANG', 'FOTO_BASP', 'FOTO_BUKTI_BASP'];
      }
      if (orderStatus.toLowerCase() === 'di lokasi tujuan') {
        showDocuments = ['FOTO_SURAT_SERAH_TERIMA', 'FOTO_BONGKAR_BARANG', 'FOTO_BASP', 'FOTO_BUKTI_BASP'];
      }
      return items.filter((item) => showDocuments.includes(item.value));
    },
  },
  methods: {
    fileUploadValidation(fileType, size, fileSizeMin = 1024000) {
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    readFile(event, form) {
      if (!event) return;
      const { orderNumber } = this.shipment;
      const timestamp = dayjs().unix();
      const { name, size } = event;
      const fileType = name.split('.').pop().toLowerCase();
      const fileSizeMin = 2048000;
      if (this.fileUploadValidation(fileType, size, fileSizeMin)) {
        const reader = new FileReader();
        reader.readAsDataURL(event);
        reader.onload = (e) => {
          form.documentType = this.documentType;
          form.documentName = `${orderNumber}-${this.documentType}-${timestamp}`;
          form.encodedImages.push(e.target.result);
        };
      }
    },
    handleChangeDocumentType() {
      const { orderNumber, destinationLocation: { locationId } } = this.shipment;
      const timestamp = dayjs().unix();
      if (!this.formUploads[0].encodedImages[0]) {
        this.formUploads[0].locationId = locationId;
        this.formUploads[0].documentType = this.documentType;
        this.formUploads[0].documentName = `${orderNumber}-${this.documentType}-${timestamp}`;
      }
    },
    handleHover(index) {
      this.formUploads[index].hover = !this.formUploads[index].hover;
    },
    handleTooltip() {
      if (this.documentType) return;
      this.showTooltip = !this.showTooltip;
    },
    addDocument() {
      const { destinationLocation: { locationId } } = this.shipment;
      this.formUploads.push({
        locationId,
        documentType: '',
        documentName: '',
        encodedImages: [],
        // tools
        file: null,
        uploadProgress: 0,
        hover: false,
      });
    },
    deleteDocument(index) {
      this.formUploads.splice(index, 1);
      if (this.formUploads.length === 0) {
        this.addDocument();
      }
    },
    getData() {
      let data = {};
      if (this.formUploads.length && this.formUploads[0].encodedImages[0]) {
        const {
          locationId,
          documentType,
          documentName,
          encodedImages,
        } = this.formUploads[0];
        data = {
          locationId,
          documentType,
          documentName,
          encodedImages,
        };
        return data;
      }
      return false;
    },
    async saveShipmentDocumentAdditional() {
      const data = this.getData();
      let error = false;
      try {
        this.isLoading = true;
        await this.submit(data);
        this.deleteDocument(0);
      } catch {
        error = true;
        this.isLoading = false;
      } finally {
        if (this.getData() && !error) {
          this.saveShipmentDocumentAdditional();
        }
        if (!this.getData() && !error) {
          this.isLoading = false;
          this.$dialog.notify.success('Berhasil menambahkan foto dokumentasi');
          this.dialog = false;
          this.$emit('fetchShipmentDetail');
        }
      }
    },
    submit(data) {
      const { id } = this.shipment;
      return new Promise((resolve, reject) => {
        const self = this;
        const config = {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            self.formUploads[0].uploadProgress = percentCompleted;
          },
        };
        self.formUploads[0].uploadProgress = 1;
        this.$_services.order.saveShipmentDocumentAdditional(data, id, config, source).then((res) => {
          resolve(res);
        }).catch((err) => {
          this.formUploads[0].uploadProgress = 0;
          reject(err);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .wrapper-trash {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
  }
  .wrapper-progress {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
  }
</style>
