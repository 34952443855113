<template>
  <section>
    <v-row>
      <v-col v-if="shipment.documents.length === 0">
        <p class="text-center">Belum ada dokumentasi</p>
      </v-col>
      <v-col else cols="12">
        <v-row>
          {{documentName = ''}}
          <v-col v-for="(document, indexDocument) in shipment.documents" cols="12" sm="4" :key="'doc'+indexDocument">
            <div style="height:48px;overflow:hidden;">
              <p v-if="document.documentName.localeCompare(documentName)" class="grey--text mb-0">
                {{documentName = document.documentName}}
              </p>
            </div>
            <viewer-image
              :src="document.documentUrl"
              height="200px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="end"
      v-if="isShowButtonShippmentDocumentAdditional"
    >
      <a
        class="mr-2"
        @click="setDialogShipmentDocumentAdditional"
      >
        <v-icon color="primary">
          mdi-plus-circle
        </v-icon>
        <span class="text--primary caption">{{$_strings.order.ADD_DOCUMENT_FOTO}}</span>
      </a>
    </v-row>
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialog"
      :urlPdf="urlPdfSelected"
    />
    <DialogShipmentDocumentAdditional
      @fetchShipmentDetail="$emit('fetchShipmentDetail')"
      ref="dialogShipmentDocumentAdditional"
    />
  </section>
</template>

<script>
import DialogShipmentDocumentAdditional from '../Dialog/ShipmentDocumentAdditional.vue';

export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
    routes: {
      type: Array,
      default: () => [],
    },
    isPageOrder: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogShipmentDocumentAdditional,
  },
  data() {
    return {
      dialogViewImage: false,
      dialogViewPdf: false,
      imageSelected: '',
      urlPdfSelected: '',
    };
  },
  computed: {
    isShowButtonShippmentDocumentAdditional() {
      const hideInStatusPage = ['confirmation_transporter', 'confirmation_driver'];
      const showInStatus = ['di lokasi penjemputan', 'di lokasi tujuan'];
      const { status } = this.$route.query;
      const { orderStatus } = this.shipment;
      const { canUpdate } = this.userAccess;
      const {
        originalLocation: {
          locationId: originLocationId,
        },
        destinationLocation: {
          locationId: destinationLocationId,
        },
      } = this.shipment;
      const findItemRoute = this.routes.find((item) => item.originLocationId === originLocationId && item.destinationLocationId === destinationLocationId);
      if (
        !showInStatus.includes(orderStatus.toLowerCase())
        || hideInStatusPage.includes(status)
        || !this.isPageOrder
      ) {
        return false;
      }
      if (findItemRoute) {
        return findItemRoute.status !== 'Dokumen Dikirim Ke Shipper' && canUpdate;
      }
      return true;
    },
  },
  methods: {
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    setDialogShipmentDocumentAdditional() {
      const { shipment } = this;
      this.$refs.dialogShipmentDocumentAdditional.shipment = shipment;
      this.$refs.dialogShipmentDocumentAdditional.dialog = true;
    },
    openDocument(item) {
      const fileType = item.documentUrl.split('.').pop().toLowerCase();
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.dialogViewImage = true;
        this.imageSelected = item.documentUrl;
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.documentUrl;
        return;
      }
      this.dialogViewImage = true;
      this.imageSelected = item.documentUrl;
    },
  },
};
</script>
